body {
	margin: 0;
}
.driver-training-page{
  background-color: #252b2f;
  text-align: center;
  height: 100vh;
  overflow: auto;
}
.driver-training-page .training-title{
	font-size: 30px;
}
.driver-training-page .driver-training-content{
  text-align: left;
}
.driver-training-page  .button-container{
	text-align: right;
  margin-top: 25px;
}
.driver-training-page  .button-container .language-button-container{
  display: inline-block;
  width: 39.5%;
  float: left;
}
.driver-training-page  .button-container .language-button-container.button-hidden {
  display: none;
}
.driver-training-page  .button-container .language-button-container-02{
  width: 38.5%;
}
.driver-training-page  .button-container .language-button-container .restart-button{
  width: 35%;
}
.driver-training-page .driver-training-button {
  font-size: 20px;
  border-radius: 10px;
  font-weight: 700;
  color: white;
  background: #0f8560;
  width: 20%;
  height: 60px;
  border: none;
  padding: 15px 0;
  cursor: pointer;
  margin: 2px 20px 20px;
  opacity: 1;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.driver-training-page .driver-training-button:disabled {
  opacity: 0.2;
}
.driver-training-page .driver-training-button.language-button {
  float: right;
  background: #545cd3;
  margin-right: 0;
  height: 52px;
  width: 29%;
}
.driver-training-page .training-info-graphic img{
  max-width: 1340px;
}

@media only screen and (max-width: 1340px) {
  .driver-training-page .training-info-graphic{
    content: "";
    clear: both;
    display: table;
  }
  .driver-training-page .training-info-graphic.portrait-view{
    width: 100%
  }
 .driver-training-page .training-info-graphic img{
  width: 100%;
 }
}
